@font-face {
    font-family: 'signpainter';
    src: url('fonts/signpainter_housescript.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'UnityText';
    src: url('fonts/UnityText-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'UnityText';
    src: url('fonts/UnityText-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-SemiBold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-Bold.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
}

html, body, div, input, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    font: inherit;
    font-size: 100%;
    font-family: Montserrat, sans-serif;
    vertical-align: baseline;
    text-decoration: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html {
    height: 100%;
    scroll-behavior: smooth;
}

body {
    height: 100%;
}

#root {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

button {
    cursor: pointer;
    border: none;
    font-family: Montserrat, sans-serif;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    z-index: 9999;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0s 0.3s;
}

.modal {
    z-index: 10000;
    position: absolute;
    overflow-y: scroll;
    left: 20px;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto auto;
    max-width: 700px;
    height: auto;
    max-height: 80vh;
    background: white;
    display: block;
    padding: 56px;
    border-radius: 24px;
    transform: translateY(-50px);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
    visibility: hidden;
}

.modal input {
    border-radius: 15px;
    box-sizing: border-box;
    color: #363140;
    display: block;
    background: #E5E5E7;
    margin-bottom: 20px;
    font-weight: 400;
    letter-spacing: 0;
    padding: 20px 40px;
    width: 100%;
    box-shadow: none;
}

.modal input:focus {
    outline: none;
    box-shadow: none;
}

.modal::-webkit-scrollbar {
    display: none;
}

.modalOverlay.show {
    opacity: 1;
    visibility: visible;
}

.modal.show {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

@media (max-width: 768px) {
    .modal {
        padding: 20px;
    }
}

.h-stack {
    display: flex;
    flex-direction: row;
}

.v-stack {
    display: flex;
    flex-direction: column;
}

.hc-stack {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.vc-stack {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}