.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 30%;
}

.h1 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 16px;
}

.subtitle {
    line-height: 24px;
    color: #888;
}

.checkbox {
    height: 32px;
    margin: 32px 0 16px 0;
    position: relative;
    width: 100%;
}

.checkbox input {
    position: absolute;
    top: 8px;
    left: 0;
    width: 24px !important;
    height: 24px;
}

.checkbox label {
    padding: 0 !important;
    position: absolute;
    top: 0;
    left: 48px;
    font-size: 1rem;
    line-height: 1.2rem;
}

.deleteInactive {
    background: #fff;
    font-weight: bold;
    cursor: default;
    color: #888;
    padding: 20px;
    font-size: 1rem;
    box-sizing: border-box;
    border-radius: 12px;
    margin-top: 16px;
}

.deleteActive {
    background: #111;
    font-weight: bold;
    color: white;
    padding: 20px;
    font-size: 1rem;
    box-sizing: border-box;
    border-radius: 12px;
    margin-top: 16px;
}

.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
}

.form label {
    font-size: .8rem;
    color: #ccc;
    padding: 16px 0 8px 0;
}

.form input {
    width: 100%;
    padding: 24px;
    border-radius: 12px;
    background: #eee;
}

@media (max-width: 1200px) {
    .profile {
        padding: 60px 20px;
    }

    .form {
        gap: 10px;
    }

    .checkbox {
        margin: 0;
    }

    .checkbox input {
        top: 0;
    }

    .checkbox label {
        width: 100%;
    }

    .deleteInactive, .deleteActive {
        margin-top: 0;
    }
}