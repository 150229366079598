.faq {
    background: #7761A9;
    width: 100%;
    padding: 40px 20px 120px;
}

.faq .title {
    margin-bottom: 40px;
    font-size: 28px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    padding: 0;
    color: #fff;
}

.faqBlock {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 40px auto;
    max-width: 1400px;
}

.faqCard {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-bottom: 1px solid #fff;
    padding: 20px 30% 55px 20px;
    cursor: pointer;
}

.faqCard a {
    text-decoration: none;
    color: #EF6B00;
}

.faqCard img {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 30px;
}

.faqCard .summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  

}

.faqCard summary::-webkit-details-marker {
    display: none;
}

.faqCard h2 {
    font-size: 20px;
    color: #fff;
    font-weight: 700;
    text-align: left;
    line-height: 126%;
    padding: 0;
}

.faqCard p {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    line-height: 140%;
    margin-top: 20px;
}

.faqCard ol {
    list-style: decimal;
    padding: 0 0 20px;
    text-align: left;
    color: #fff;
    line-height: 140%;
}

.faqCard ul {
    list-style-type: disc;
    padding-left: 40px;
    text-align: left;
    color: #fff;
    line-height: 140%;
}

.faqCard li {
    margin: 0 0 0 20px;
    padding: 20px 0 0;
    text-align: left;
    line-height: 140%;
}

.faqCard ol > li {
    list-style-type: decimal;
    padding-left: 20px;
}

.tableContainer {
    width: 100%;
    margin: 20px auto;
}

.productTable {
    width: 100%;
    border-collapse: collapse;
}

.productTable th, .productTable td {
    padding: 10px;
    text-align: left;
    color: #fff;
}

.productTable th {
    font-weight: bold;
}

@media (max-width: 768px) {
    .productTable td{
        background: transparent;;
    }
    .faq {
        padding: 0 20px 40px;
    }

    .faq .title {
        margin-bottom: 0;
        font-size: 32px;
    }

    .faqCard {
        padding: 20px 10% 15px 0;
    }

    .faqCard img {
        position: absolute;
        right: 0;
        top: 20px;
        width: 20px;
    }

    .faqCard p {
        font-size: 14px;
    }

    .faqCard ul {
        padding: 0;
    }

    .faqCard li {
        margin: 0 0 0 20px;
        padding: 20px 0 0;
        font-size: 12px;
    }

    .faqCard h2 {
        font-size: 16px!important;
    }
}

@media (max-width: 450px) {
    .faqCard img {
        position: absolute;
        right: 0;
        top: 20px;
        width: 20px;
    }

    .faq .title {
        font-size: 22px;
    }
}