.prizes {
    padding: 20px;
}

.prizesHead {
    display: flex;
    flex-direction: row;
    margin: 20px auto;
    align-items: center;
    justify-content: center;
}

.back {
    color: #369;
    position: absolute;
    left: 20px;
}

.prizesHead button {
    background: rgba(103, 186, 103, 0.04);
    color: rgba(16, 113, 205, 0.7);
    border: 1px solid rgba(20, 95, 179, 0.4);
    font-size: .8rem;
    font-weight: bold;
    border-radius: 8px;
    padding: 16px 16px;
}

.prizesHead button:active {
    background: rgba(16, 113, 205, 0.7);;
    color: white;
    border: 1px solid rgba(20, 95, 179, 0.4);
}

.prizes h1 {
    font-size: 2rem;
    color: #369;
}

.id {
    color: #888;
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

.id:before {
    content: "ID";
}

.id:before {
    font-size: .6rem;
    font-weight: normal;
}

.id:before {
    content: "ID";
}

.name {
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

.name:before {
    font-size: .6rem;
    font-weight: normal;
}

.name:before {
    content: "Название";
}

.newPrize {
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
}

.newPrize input {
    padding: 8px;
    font-size: 16px;
    width: 1000%;
}

.newPrize button {
    padding: 8px 16px;
    background: rgba(103, 186, 103, 0.04);
    color: rgba(0, 128, 0, 0.7);
    border: 1px solid rgba(103, 186, 103, 0.4);
    border-radius: 8px;
    cursor: pointer;
}

.newPrize button:active {
    background: rgba(0, 128, 0, 0.7);
    color: white;
    border: 1px solid rgba(103, 186, 103, 0.4);
}

.table {
    margin-bottom: 20px;
}

.row {
    display: flex;
    gap: 20px;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}

.name {
    flex: 1;
}

.id {
    flex: 1;
    text-align: center;
}

.pagination {
    display: flex;
    justify-content: center;
    gap: 5px;
}

.activePage {
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
}

.inactivePage {
    padding: 5px 10px;
    background-color: #f1f1f1;
    color: #333;
    border: none;
    cursor: pointer;
}

.inactivePage:hover {
    background-color: #ddd;
}
