.public {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.public .container1 .text .head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.public .container1 .text .head .headLeft, .headRight {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
}

.public .container1 .text .head p {
    color: black;
    text-align: left;
}

.public .title {
    font-size: 44px;
    font-weight: 700;
    margin-top: 40px;
}

.textBlock .subtitle {
    font-size: 20px;
    margin: 0 0 20px;
    text-align: left;
}

.text .subtitle2 {
    color: black;
    text-align: left;
    margin-top: 40px;
}

.container1 {
    padding: 140px 0;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
}

.public .container1 .text .textBlock {
    margin: 50px 0 20px;
}

.textBlock h2 {
    font-size: 24px;
    margin: 50px 0 20px;
    text-align: center;
    padding: 0;
}

.boldText {
    font-weight: bold;
    color: black;
    font-style: italic;
}

.textBlock p {
    color: hsla(0, 0%, 5%, .8);
    text-align: left;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0;
    margin: 0 0 16px;
}

.public .container1 .textBlock ul {
    list-style: circle;
    padding: 0 0 20px;
}

.public .container1 .textBlock ul li {
    color: hsla(0, 0%, 5%, .8);
    text-align: left;
    margin: 0 0 0 20px;
    padding: 20px 0 0;
    letter-spacing: 0;
    line-height: 140%;
}

.tableContainer {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    overflow-y: auto;
}

.privacyTable {
    width: 100%;
    border-collapse: collapse;
}

.privacyTable th,
.privacyTable td {
    border: 1px solid black;
    padding: 8px;
    text-align: center;
    font-size: 14px;
}

.privacyTable th {
    font-weight: bold;
}

.privacyTable td {
    vertical-align: top;
    line-height: 1.5;
}

@media (max-width: 1400px) {
    .container1 {
        padding: 140px 20px;
    }
}

@media (max-width: 1000px) {
    .mobileTitle {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .container1 {
        padding: 80px 20px;
    }

    .public .title {
        font-size: 32px;
    }

    .public .container1 .text .head {
        flex-direction: column;
    }

    .public .container1 .text .textBlock {
        margin: 0;
    }

    .textBlock h2 {
        font-size: 20px;
        margin: 0 0 20px;
    }

    .textBlock p {
        font-size: 14px;
        margin: 0 0 14px;
    }

    .public .container1 .textBlock ul li {
        font-size: 14px;
    }

    .textBlock .tabsTitle {
        margin-top: 40px;
    }

    .table {
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .textBlock .subtitle {
        font-size: 16px;
    }
}

@media (max-width: 450px) {
    .public .title {
        font-size: 18px!important;
    }
}