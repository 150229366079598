.public {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.public .container1 {
    padding: 140px 0;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
}

.public .container1 .text .title {
    font-size: 42px;
    text-align: left;
    font-weight: bold;
    color: hsla(0,0%,5%,.8);
}

.public .container1 .text .subtitle {
    color: hsla(0,0%,5%,.8);
    font-size: 16px;
    text-align: right;
    margin-top: 26px;
}

.public .container1 .text .textBlock {
    margin: 50px 0 20px;
}

.textBlock h2 {
    font-size: 24px;
    margin: 50px 0 20px;
    text-align: left;
    padding: 0;
    color: hsla(0,0%,5%,.8);
}

.textBlock p {
    color: hsla(0,0%,5%,.8);
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0;
    text-align: left;
    margin: 0 0 16px;
}

.public .container1 .textBlock ul {
    list-style: circle;
    padding: 0 0 20px;
}

.public .container1 .textBlock ul li {
    font-size: 16px;
    color: hsla(0,0%,5%,.8);
    margin: 0 0 0 20px;
    padding: 20px 0 0;
    letter-spacing: 0;
    line-height: 140%;
}

@media (max-width: 1400px) {
    .public .container1 .text {
        padding: 0 20px;
    }
}

@media (max-width: 768px) {
    .public .container1 {
        padding: 120px 0;
    }

    .public .container1 .text .title {
        font-size: 32px;
    }

    .public .container1 .text .subtitle {
        font-size: 14px;
        margin: 26px 0 0;
    }

    .public .container1 .text .textBlock {
        margin: 20px 0 0;
    }

    .textBlock h2 {
        font-size: 20px;
        margin: 0 0 20px;
    }

    .textBlock p {
        font-size: 14px;
        margin: 0 0 14px;
    }

    .public .container1 .textBlock ul li {
        font-size: 14px;
    }
}

@media (max-width: 450px) {
    .public .container1 .text .title {
        font-size: 22px;
    }
}