.public {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.raffle {
    color: black;
    background: white;
    min-width: 220px;
    width: 100%;
    text-align: left;
    padding: 22px 32px;
    border-radius: 15px;
    line-height: 140%;
    transition: 0.3s;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
}
.searchMob{
display: none;
}
.searchDesc{
    display: block;
}

.raffle:hover,.activeDate {
    color: white;
    background: #7761a9;
}

.container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 150px 20px 15px;
}

.public .title {
    font-size: 42px;
    text-align: left;
    font-weight: 700;
    padding: 0;
}

.public .subtitle {
    color: gray;
    line-height: 140%;
    text-align: left;
    margin-top: 22px;
}

.public .block {
    display: flex;
    flex-direction: row;
    margin-top: 100px;
    padding: 14px;
    background: #e7e7e7;
    border-radius: 20px;
}

.public .block .draw {
    display: flex;
    flex-direction: column;
    padding: 22px 44px 22px 22px;
    gap: 20px;
    width: 25%;
}

.public .block .draw p {
    color: black;
    background: white;
    min-width: 220px;
    width: 100%;
    text-align: left;
    padding: 22px 32px;
    border-radius: 15px;
    line-height: 140%;
    transition: 0.3s;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
}

.public .block .draw p:hover {
    color: white;
    background: black;
}

.public .block .winners {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: 75%;
    background: white;
    padding: 32px 26px 26px;
    border-radius: 10px;
}

.public .block .winners input {
    width: 100%;
    border: 1px solid hsla(0,0%,5%,.35);
    border-radius: 70px;
    line-height: 140%;
    padding: 12px 14px 12px 42px;
    font-size: 14px;
}

.table {
    border-collapse: separate;
    border-spacing: 0 4px;
    width: 100%;
    table-layout: fixed;
}

.table th {
    font-size: 14px;
    border: none;
    background: none;
    color: grey;
    font-weight: 700;
    padding-left: 16px;
}

.table tr {
    background: none;
}

.table td {
    background: #f4f4f4;
    padding: 12px 16px;
    font-size: 14px;
    border: none;
    vertical-align: middle;
    line-height: 140%;
}

.table tr td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.table tr td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.table td:nth-child(3) {
    max-width: 200px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
}

.winners ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-top: 40px;
}

.winners ul li {
    font-size: 14px;
    cursor: pointer;
}


.mobileTable {
    display: none;
}

.drawMobile {
    display: none;
}

@media (max-width: 1200px) {
    .searchMob{
        display: block;
        }
        .searchDesc{
            display: none;
        }
    .public .block {
        flex-direction: column;
        margin-top: 20px;
    }

    .public .block {
        padding: 0;
    }

    .drawTitle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .drawSubtitle {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .public .block .winners {
        background: #e7e7e7;
        gap: 20px;
        width: 100%;
        padding: 0 20px 20px;
    }

    .public .block .draw {
        display: none;
    }

    .public .block .drawMobile {
        padding: 20px;
        gap: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .public .block .drawMobile p {
        color: black;
        background: white;
        min-width: 220px;
        width: 100%;
        text-align: left;
        padding: 22px 32px;
        border-radius: 15px;
        line-height: 140%;
        transition: 0.3s;
        font-weight: 700;
        font-size: 14px;
        cursor: pointer;
    }

    .public .block .drawMobile p:hover {
        color: white;
        background: black;
    }

    .drawMobile {
        display: flex;
    }

    .table {
        display: none;
    }

    .mobileTable {
        display: flex;
        flex-direction: column;
        gap: 20px;
        text-align: left;
        width: 100%;
    }

    .mobileTable .blockTable {
        display: flex;
        flex-direction: column;
        gap: 5x;
        padding: 15px;
        background: white;
        border-radius: 15px;
        align-items: start;
    }

    .mobileTable .blockTable span {
       line-height: 140%;
       margin-bottom: 5px;
    }
    
    .mobileTable .blockTable p,
    .mobileTable .blockTable span{
        color:black;
        font-size: 12px;
        
    }
    .container {
        margin: 0;
        padding-inline: 20px;
    }
    .mobileTable .blockTable p{
        color: grey;;
    }
}

@media (max-width: 768px) {
    .public .title {
        font-size: 32px;
    }

    .container {
        margin: 0;
        padding-inline: 20px;
        padding-top: 100px;
        padding-bottom: 10px;
    }

    .table tr {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }
}

@media (max-width: 450px) {
    .public .title {
        font-size: 22px;
    }
}