.project {
    max-width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
}

.h1 {
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    text-align: left;
    font-size: 2rem;
    font-weight: bold;
    border-bottom: 1px solid #eee;
}

.tabs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
}

.tab {
    padding: 10px;
    border-bottom: 1px solid #eee;
    background: #eee;
    border-radius: 4px;
}

.tabActive {
    background: lightgreen;
}