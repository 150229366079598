.user {
    max-width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    gap: 10px;
}

.label {
    margin: 0;
}

.input {
    padding: 8px;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1rem;
}


.button {
    background: #eee;
    color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 10px;
    box-sizing: border-box;
    border-radius: 5px;
    font-weight: bold;
}

.button:hover {
    background: #ddd;
}

.buttonPrimary {
    background: lightgreen;
}

.buttonPrimary:hover {
    background: #67ba67;
}

.row {
    display: grid;
    grid-template-columns: 5% 1fr 1fr 1fr 1fr;
    gap: 16px;
    border-radius: 4px;
    text-align: left;
    border-bottom: 1px solid #eee;
    padding: 6px 0 12px 0;
    box-sizing: border-box;
}

.link {
    color: #369;
}

.id,
.name,
.email,
.birthDate,
.isVerified {
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

.name {
    color: #369;
}

.id:before,
.name:before,
.email:before,
.birthDate:before,
.isVerified:before {
    font-size: .6rem;
    font-weight: normal;
}

.id:before {
    content: "ID";
}

.name:before {
    content: "Название";
}

.email:before {
    content: "Email";
}

.birthDate:before {
    content: "Дата рождения";
}

.isVerified:before {
    content: "Верификация";
}

.unverified {
    color: red;
}

.verified {
    color: green;
}

.activePage {
    color: green;
}

.inactivePage {
    color: red;
}