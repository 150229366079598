.raffle {
    max-width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
}

.tabs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
}

.tab {
    padding: 10px;
    border-bottom: 1px solid #eee;
    background: #eee;
    border-radius: 4px;
}

.receiptCol {
    border-bottom: 1px solid #eee;
}

.receiptCodes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.codeBadge {
    background: rgba(103, 186, 103, 0.04);
    color: rgba(0, 128, 0, 0.7);
    border: 1px solid rgba(103, 186, 103, 0.4);
    font-size: .8rem;
    font-weight: bold;
    border-radius: 16px;
    padding: 4px 16px;
}

.tabActive {
    background: lightgreen;
}

.h1 {
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    text-align: left;
    font-size: 2rem;
    font-weight: bold;
    border-bottom: 1px solid #eee;
}

.detailsLink {
    color: #369;
    font-weight: bold;
    margin-bottom: 20px;
    text-decoration: none;
}

.table {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.row {
    display: grid;
    align-items: center;
    gap: 16px;
    border-radius: 4px;
    text-align: left;
    padding: 6px 0 12px 0;
    box-sizing: border-box;
}

.button {
    padding: 0;
    background: none;
}

.link {
    color: #369;
}

.id, .user, .receipt, .number, .code, .isAccepted, .created, .reason, .receiptCodesCount, .acceptedAt, .uploaded {
    display: flex;
    flex-direction: column;
    font-weight: 400;
}

.receipt {
    position: relative;
}

.reason {
    text-align: start;
    padding: 8px 0;
}

.receipt img {
    position: absolute;
    top: -20px;
    border: 1px solid #eee;
    object-fit: cover;
    border-radius: 4px;
    height: 40px;
    width: 40px;
}

.id {
    color: #888;
}

.receipt {
    color: #369;
}

.searchContainer {
    width: 100%;
    margin: 20px auto;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
}

.searchInput {
    width: 100%;
}

.searchContainer .moderation {
    background: rgba(103, 186, 103, 0.04);
    color: rgba(16, 113, 205, 0.7);
    border: 1px solid rgba(20, 95, 179, 0.4);
    font-size: .8rem;
    font-weight: bold;
    border-radius: 8px;
    padding: 16px 16px;
}

.searchContainer .moderation.active {
    background: rgba(16, 113, 205, 0.7);;
    color: white;
    border: 1px solid rgba(20, 95, 179, 0.4);
}

.searchContainer .accept {
    background: rgba(103, 186, 103, 0.04);
    color: rgba(0, 128, 0, 0.7);
    border: 1px solid rgba(103, 186, 103, 0.4);
    font-size: .8rem;
    font-weight: bold;
    border-radius: 8px;
    padding: 16px 16px;
}

.searchContainer .accept.active {
    background: rgba(0, 128, 0, 0.7);
    color: white;
    border: 1px solid rgba(103, 186, 103, 0.4);
}

.searchContainer .reject {
    background: rgba(103, 186, 103, 0.04);
    color: rgba(243, 3, 59, 0.7);
    border: 1px solid rgba(186, 11, 37, 0.4);
    font-size: .8rem;
    font-weight: bold;
    border-radius: 8px;
    padding: 16px 16px;
}

.searchContainer .reject.active {
    background: rgba(243, 3, 59, 0.7);
    color: white;
    border: 1px solid rgba(186, 11, 37, 0.4);
}

.id:before,
.user:before,
.number:before,
.code:before,
.isAccepted:before,
.created:before,
.reason:before,
.uploaded:before,
.receiptCodesCount:before,
.acceptedAt:before {
    font-size: .6rem;
    font-weight: normal;
}

.id:before {
    content: "ID";
}

.user:before {
    content: "Участник";
}

.number:before {
    content: "Присвоенный номер";
}

.code:before {
    content: "Код чека";
}

.reason:before {
    content: "Отклонено";
}

.uploaded:before {
    content: "Загружено";
}

.receiptCodesCount:before {
    content: "Коды";
}

.isAccepted:before {
    content: "Верификация";
}

.created:before {
    content: "Создано";
}

.acceptedAt:before {
    content: "Принято";
}

.created, .finished {
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

.created:before {
    content: "Дата старта";
    font-size: .6rem;
    font-weight: normal;
}

th, td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

.detailsLink:hover {
    text-decoration: underline;
}
.wide{
    grid-template-columns: 20% 2fr 1fr 1fr 1fr!important;
}
@media (max-width: 768px) {
    .searchContainer {
       flex-wrap: wrap;
       gap:10px;
    }
    
    .searchInput {
        flex:1 1 100%;
    }
    h1 {
        font-size: 28px;
    }

    .detailsLink {
        font-size: 18px;
    }

    table, thead, tbody, th, td, tr {
        display: block;
    }

    th {
        display: none;
    }

    tr {
        margin-bottom: 8px;
    }

    tr:last-child {
        margin-bottom: 0;
    }

    td {
        text-align: right;
        position: relative;
        padding-left: 50%;
        border-bottom: none;
        background: #fff;
    }

    td:last-child {
        border-bottom: 1px solid #ccc;
    }

    td::before {
        content: attr(data-label);
        position: absolute;
        left: 8px;
        width: 50%;
        text-align: left;
        font-weight: bold;
    }
}

.controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding-bottom: 16px;
    gap: 20px;
}

.export {
    color: #369;
    flex: 1;
}
