.form {
    padding: 100px 0 0px 0;
    background: no-repeat center 40px / cover;
    background-color: #7761A9;
}

.form .modalForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    max-width: 740px;
    position: relative;
    background: white;
    padding: 55px 100px 75px;
    border-radius: 20px;
    margin: 120px auto;
}

.regInactive {
    width: 100%;
    background: #e4d9ff;;
    font-weight: bold;
    cursor: default;
    color: #c2c2c2;
    padding: 20px;
    font-size: 1rem;
    box-sizing: border-box;
    border-radius: 12px;
    cursor:not-allowed;
}

.regActive {
    width: 100%;
    background: #111;
    font-weight: bold;
    color: white;
    padding: 20px;
    font-size: 1rem;
    box-sizing: border-box;
    border-radius: 12px;
}

.regClosed {
    text-align: center;
}

.h2 {
    color: #363140;
    font-size: 1.6rem;
    text-transform: uppercase;
    text-align: center;
}

.subtitle {
    font-size: 1;
    line-height: 1.3rem;
    color: #363140;
    max-width: 320px;
    margin-top: 5px;
    margin-bottom: 25px;
}
.subtitlePass{
    margin: 10px 0;
    max-width: 100%;
}

.checkContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 25px;
}

.checkContainer span {
    cursor: pointer;
    color: #7761A9;
    font-size: 14px;
}

.check {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    font-size: 14px;
    color: #363140;
}

.checkRules {
    display: flex;
    gap: 20px;
    align-items: center;
    font-size: 14px;
    color: hsla(0, 0%, 5%, 0.6);
}

.checkRules input[type="checkbox"] {
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 4px;
    appearance: none;
    margin: 0;
    padding: 0;
    min-width: 20px;
    min-height: 20px;
}

.checkRules input[type="checkbox"]:checked {
    border-color: #7761A9;
}

.checkRules input[type="checkbox"]:checked::after {
    content: "✓";
    color: #7761A9;
    font-size: 14px;
    position: relative;
    left: 2px;
}

.checkRules span {
    flex-grow: 1;
}

.check input[type="checkbox"] {
    width: 20px;
    height: 20px;
    border: 2px solid #7761A9;
    border-radius: 4px;
    appearance: none;
    margin: 0;
    padding: 0;
    font-weight: 800;
}

.check input[type="checkbox"]:checked {
    border-color: #7761A9;
}

.check input[type="checkbox"]:checked::after {
    content: "✓";
    font-weight: 800;
    color: #7761A9;
    font-size: 14px;
    position: relative;
     left: 2px;
}

.modalForm input {
    border-radius: 15px;
    box-sizing: border-box;
    color: #363140;
    display: block;
    background: #E5E5E7;
    font-weight: 400;
    letter-spacing: 0;
    padding: 20px 40px;
    width: 100%;
    box-shadow: none;
}
.modalForm input[type="date"]{
    height:60px;
    width:100%;
}
.modalForm input:focus {
    outline: none;
    box-shadow: none;
}

.textBlock {
    display: flex;
    flex-direction: column;
    max-height: 250px;
    overflow-y: auto;
    padding: 10px;
    text-align: left;
}

.textBlock h2 {
    color: #363140;
    padding: 0;
    margin-top: 20px;
    font-size: 20px;
}

.textBlock h3 {
    font-size: 18px;
    color: #363140;
}

.textBlock p {
    font-size: 14px;
    margin-top: 20px;
    color: hsla(0, 0%, 5%, .8);
    text-align: left;
}

.textBlock ul {
    list-style: circle;
    padding: 0 0 20px;
}

.textBlock ul li {
    color: hsla(0, 0%, 5%, .8);
    text-align: left;
    margin: 0 0 0 20px;
    padding: 20px 0 0;
    letter-spacing: 0;
    line-height: 140%;
}

.darkButton {
    border-radius: 15px;
    box-sizing: border-box;
    background: #7761A9;
    color: #fff;
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 0;
    padding: 20px 40px;
    width: 100%;
    box-shadow: none;
}

.lightButton {
    border-radius: 15px;
    box-sizing: border-box;
    background: #E7E7E7;
    color: #111;
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 0;
    padding: 20px 40px;
    width: 100%;
    box-shadow: none;
}

.modalForm .error {
    border: 1px solid red;
    background-color: #ffe6e6;
}

.errorMsg {
    color: red;
    font-size: 0.9em;
}

.error {
    margin-top: 10px;
}

.passwordContainer {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
}

.passwordContainer input {
    width: 100%;
}

.passwordContainer button {
    position: absolute;
    right: 20px;
    top: 6px;
    bottom: 0;
    background: none;
    font-size: 15px;
}

@media (max-width: 1200px) {
    .form .modalForm {
        padding: 40px;
        width: 80%;
        margin: 60px auto;
    }
}

@media (max-width: 768px) {
    .form .modalForm {
        padding: 20px;
        width: 90%;
    }

    h2 {
        font-size: 32px!important;
    }

    .subtitle {
        font-size: 16px;
        padding: 0;
    }

    .check, span {
        font-size: 14px;
        text-align: left;
    }

    .entry, .registration {
        font-size: 14px;
    }

    .checkRules {
        font-size: 12px;
        gap: 10px;
    }

    .checkRules input[type="checkbox"] {
        min-width: 20px;
        min-height: 20px;
    }

    .checkRules input[type="checkbox"]:checked::after {
        left: 2px;
    }
}

@media (max-width: 450px) {
    h2 {
        font-size: 22px !important;
    }

    .subtitle,.subtitlePass{
        font-size: 14px !important;
      
    }

    .checkContainer span {
        font-size: 12px;
    }

    .check span {
        font-size: 10px;
    }
  .modalForm label{
font-size: 14px;;
  }
}
