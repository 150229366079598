.title {
    position: fixed;
    bottom: 0;
    text-align: center;
    width: 100%;
    padding: 16px;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    color: #333;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.2);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.link {
    color: blue;
}

.material-icons {
    position: absolute;
    right: 16px;
}

.title button {
    border: none;
    background: none;
}

@media (max-width: 768px) {
    .title h3 {
        font-size: 10px;
        line-height: 16px;
    }
}
