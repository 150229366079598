.raffle {
    max-width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    padding: 20px;
}

.h1 {
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    text-align: left;
    font-size: 2rem;
    font-weight: bold;
    border-bottom: 1px solid #eee;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: left;
    width: 100%;
}

.label {
    margin: 0;
}

.input {
    padding: 8px;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1rem;
}

.controls {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.button {
    background: #eee;
    color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 10px;
    box-sizing: border-box;
    border-radius: 5px;
    font-weight: bold;
}

.button:hover {
    background: #ddd;
}

.buttonPrimary {
    background: lightgreen;
}

.buttonPrimary:hover {
    background: #67ba67;
}