.public {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.contacts {
    padding: 205px 20px 250px;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
}

.contact {
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: left;
    width: 50%;
}

.contact .title {
    font-size: 42px;
    text-align: left;
    font-weight: 700;
    padding: 0;
}

.contact .subtitle {
    margin-top: 50px;
}

.contact p {
    color: hsla(0, 0%, 5%, .8);
    line-height: 140%;
}

.contact a {
    margin-left: 10px;
}

@media (max-width: 1200px) {
    .contacts {
        background-image: none!important;
        padding: 205px 20px 250px;
    }

    .contact {
        width: 80%;
    }
}

@media (max-width: 768px) {
    .contact .title {
        font-size: 32px;
    }

    .contact {
        width: 100%;
    }
}

@media (max-width: 450px) {
    .contact .title {
        font-size: 22px;
    }
}