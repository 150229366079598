.rules {
    padding: 60px 40px;
    background: #EF6B00;
    width: 100%;
}

.rules .container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin: 0 auto;
    max-width: 1400px;
    width: 100%;
}

.rules .startBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.rules .startBlock .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 32%;
}

.participateButton,
.rulesButton {
    background: #D1C6F0;
    color: #000;
    width: 190px;
    font-weight: 700;
    font-size: 18px;
    border-radius: 40px;
    padding: 22px 32px;
    text-align: center;
}

.rulesButton {
    background: #fefefe;
    color: #000;
}

.participateButton:hover {
    background: #c0b0f3;
}

.rulesButton:hover {
    background: #ccc;
}

.title {
    font-size: 28px;
    width: 30%;
    text-align: left;
    color: white;
    font-size: 28px;
    padding: 0;
    text-transform: uppercase;
}

.cards {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 20px;
    flex-wrap: wrap;
}


.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}



.card img {
    width: 196px;
    height: 200px;
}

.card h3 {
    color: white;
    font-weight: 700;
    font-size: 25px;
}

.card p {
    color: white;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
}

@media (max-width: 1200px) {
    .rules .startBlock {
        align-items: flex-start;
    }

    .rules .startBlock .buttons {
        flex-direction: column;
        width: 100%;
        /* margin-top: 20px; */
    }

    .participateButton,
    .rulesButton {
        width: 100%;
    }

    .title {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .rules .startBlock .buttons {

        margin-top: 20px;
    }

    .rules .container {
        gap: 20px;
    }

    .title {
        text-align: center;
        font-size: 22px !important;
    }

    .rules .startBlock {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .cards {
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }

    .card {
        width: 20%;
    }



    .card h3 {
        color: white;
        font-weight: 700;
        font-size: 20px;
    }

    .card p {
        font-size: 15px;
    }


    .rules {
        margin-top: -80px;
        padding: 40px 40px;

    }
}

@media (max-width: 768px) {


    .card {
        width: 100%;
    }

    .card img {
        width: 100px;
        height: 95px;
    }

    .rules {
        padding: 40px 20px;
    }

    .participateButton,
    .rulesButton {

        width: 200px;

    }
}