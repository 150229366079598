.receipts {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
}

.receiptCodes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.receiptCodesRow {
    margin-bottom: 8px;
}

.codeBadge {
    background: rgba(103, 186, 103, 0.04);
    color: rgba(0, 128, 0, 0.7);
    border: 1px solid rgba(103, 186, 103, 0.4);
    font-size: .7rem;
    font-weight: bold;
    border-radius: 16px;
    padding: 2px 8px;
}

.h1 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 16px 0 32px 0;
}

.header {
    background-color: #fff;
    font-weight: bold;
}

.container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 210px 0 140px;
    background: left 84px bottom -111px / 180px auto no-repeat;
}

.receipts .title {
    font-size: 42px;
    text-align: left;
    font-weight: 700;
}

.receipts .subtitle {
    color: gray;
    line-height: 140%;
    text-align: left;
    margin-top: 22px;
}

.receipts .block {
    display: flex;
    flex-direction: row;
    margin-top: 100px;
    padding: 14px;
    background: #e7e7e7;
    border-radius: 20px;
}

.receipts .block .draw {
    display: flex;
    flex-direction: column;
    padding: 22px 44px 22px 22px;
    gap: 20px;
}

.receipts .block .draw p {
    color: black;
    background: white;
    min-width: 220px;
    width: 100%;
    text-align: left;
    padding: 22px 32px;
    border-radius: 15px;
    line-height: 140%;
    transition: 0.3s;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
}

.receipts .block .draw p:hover {
    color: white;
    background: black;
}

.receipts .block .winners {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: 75%;
    background: white;
    padding: 32px 26px 26px;
    border-radius: 10px;
}

.receipts .block .winners input {
    width: 100%;
    max-width: 520px;
    border: 1px solid hsla(0, 0%, 5%, .35);
    border-radius: 70px;
    line-height: 140%;
    padding: 12px 14px 12px 42px;
    font-size: 14px;
}

.table {
    width: 100%;
    table-layout: fixed;
}

.table th {
    font-size: 14px;
    border: none;
    background: none;
    color: grey;
    font-weight: 700;
    padding-left: 16px;
}

.table tr {
    background: none;
}

.table td {
    background: #f4f4f4;
    padding: 12px 16px;
    font-size: 14px;
    border: none;
    vertical-align: middle;
    line-height: 140%;
}

.table tr td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.table tr td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.table td:nth-child(3) {
    max-width: 200px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
}

.winners ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-top: 40px;
}

.winners ul li {
    font-size: 14px;
    cursor: pointer;
}

.mobileTable {
    display: none;
}

.receipt img {
    border: 1px solid #eee;
    object-fit: cover;
    border-radius: 4px;
    height: 40px;
    width: 80px;
}

@media (max-width: 1200px) {
    .table {
        display: block;
    }
}

@media (max-width: 768px) {
    .receipts .title {
        font-size: 32px;
    }

    .table {
        display: none;
    }

    .mobileTable {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .mobileTable .blockTable {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        justify-content: space-between;
        background: #f4f4f4;
        padding: 12px 16px;
        font-size: 14px;
        line-height: 140%;
        border-radius: 12px;
    }
}

@media (max-width: 450px) {
    .receipts .title {
        font-size: 22px;
    }
}

.gridTableContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.gridTable {
    background: #f2f2f2;
    border-radius: 16px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
}

.gridTableHeader {
    font-size: .8rem;
    font-weight: bold;
    display: flex;
    justify-content: start;
}

.gridTableCell {
    font-size: .8rem;
    display: flex;
    justify-content: start;
    align-items: center;
}

.declineReason {
    font-size: .8rem;
    line-height: 1.4;
    width: 100%;
    grid-column: span 5;
    display: flex;
    flex-direction: row;
}

.emptyRow {
    grid-column: span 5;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;
}

.emptyRow span {
    background: rgba(103, 186, 103, 0.04);
    color: rgba(0, 128, 0, 0.7);
    border: 1px solid rgba(103, 186, 103, 0.4);
    font-size: .6rem;
    font-weight: bold;
    border-radius: 16px;
    padding: 2px 8px;
}

@media (max-width: 768px) {
    .gridTable {
        display: block;
    }

    .gridTableHeader,
    .gridTableCell {
        display: block;
        width: 100%;
        font-size: .6rem;
    }

    .declineReason {
        font-size: .6rem;
        padding: 8px;
    }

    .gridTableHeader {
        display: none;
    }

    .gridTableCell {
        position: relative;
        display: flex;
        justify-content: end;
        box-sizing: border-box;
        padding: 8px;
    }

    .gridTableCell::before {
        content: attr(data-label);
        position: absolute;
        left: 8px;
        font-weight: bold;
    }

    .emptyRow {
        padding: 8px
    }
}