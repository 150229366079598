.public {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.public h1 {
    font-size: 44px;
    text-align: left;
    font-weight: 700;
}

.container1 {
    padding: 140px 0;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
}

.public .container1 .text {
    max-width: 930px;
}

.public .container1 .text .textBlock {
    margin: 50px 0 20px;
    max-width: 850px;
}

.textBlock h2 {
    font-size: 24px;
    margin: 50px 0 20px;
    text-align: left;
    padding: 0;
    color: hsla(0, 0%, 5%, .8);
}

.textBlock p {
    color: hsla(0, 0%, 5%, .8);
    text-align: left;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0;
    margin: 0 0 16px;
}

.textBlock .tabsTitle {
    margin-top: 87px;
}

.table .tableTitle {
    align-items: stretch;
    display: grid;
    gap: 6px;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
}

.mobileTitle {
    display: none;
    padding: 20px;
    background: #e7e7e7;
    border-radius: 10px;
}

.mobileTitle p {
    font-size: 16px;
}

.table .tableTitle p {
    font-size: 14px;
    font-weight: 700;
    min-height: 56px;
    padding: 14px 16px;
    align-items: center;
    background: #e7e7e7;
    border-radius: 10px;
    line-height: 140%;
    margin-bottom: 6px;
}

.table .tableSubtitle {
    align-items: stretch;
    background: none;
    display: grid;
    gap: 6px;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    padding: 0;
}

.table .tableSubtitle span {
    font-size: 14px;
    background: #f4f4f4;
    border-radius: 10px;
    color: hsla(0,0%,5%,.8);
    line-height: 140%;
    padding: 20px;
}

@media (max-width: 1400px) {
    .container1 {
        padding: 140px 20px;
    }
}

@media (max-width: 1000px) {
    .table .tableTitle {
        display: none;
    }

    .mobileTitle {
        display: flex;
        flex-direction: column;
    }

    .table .tableSubtitle {
        display: flex;
        flex-direction: column;
        gap: 10px;
        text-align: left;
    }

    .table .tableSubtitle span {
        text-align: left;
        background: none;
        border-radius: 0;
        color: hsla(0, 0%, 5%, .8);
        line-height: 140%;
        padding: 0;
    }

    .table .tableSubtitle > span {
        display: none;
    }
}

@media (max-width: 768px) {
    .container1 {
        padding: 60px 20px;
    }

    .public h1 {
        font-size: 32px;
    }

    .public .container1 .text .textBlock {
        margin: 20px 0 0;
    }

    .textBlock h2 {
        font-size: 20px;
        margin: 0 0 20px;
    }

    .textBlock p {
        font-size: 14px;
        margin: 0 0 14px;
    }

    .public .container1 .textBlock ul li {
        font-size: 14px;
    }

    .textBlock .tabsTitle {
        margin-top: 40px;
    }

    .table {
        margin-bottom: 20px;
        margin-top: 20px;
    }
}

@media (max-width: 450px) {
    .public h1 {
        font-size: 22px;
    }
}