.auth {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px;
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    max-width: 650px;
    width: 100%;
    border-radius: 12px;
}

h2 {
    font-size: 32px;
    color: #0c0c0c;
}

.email, .password {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

label {
    text-align: left;
}

input {
    padding: 12px 16px;
    border-radius: 12px;
    border: 1px solid #d1d1d1;
    background: #f9f9f9;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    font-size: 16px;
    color: #0c0c0c;
}

input:focus {
    outline: none;
    border-color: #1b1b22;
    box-shadow: 0 0 8px rgba(0, 122, 255, 0.4);
    background: #fff;
}

input::placeholder {
    color: #9e9e9e;
    font-size: 14px;
}

input:hover {
    border-color: #282a2e;
    background: #f7f7f7;
}


.option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.submit {
    background: #0c0c0c;
    border-radius: 8px;
    color: white;
    padding: 12px 16px;
    width: 35%;
}

@media (max-width: 768px) {
    h2 {
        font-size: 22px;
    }

    .submit {
        width: 100%;
    }
}