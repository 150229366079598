.headerPublic {
    position: fixed;
    display: inline-flex;
    top: 0;
    background: white;
    width: 100%;
    height: 105px;
    gap: 68px;
    z-index: 1000;
}

.regInactive {
    width: 100%;
    background: #e4d9ff;;
    font-weight: bold;
    cursor: default;
    color: #c2c2c2;
    padding: 20px;
    font-size: 1rem;
    box-sizing: border-box;
    border-radius: 12px;
    cursor:not-allowed;
}

.regActive {
    width: 100%;
    background: #111;
    font-weight: bold;
    color: white;
    padding: 20px;
    font-size: 1rem;
    box-sizing: border-box;
    border-radius: 12px;
    margin-top: 16px;
}

.regClosed {
    text-align: center;
}

.headerPublic .nav {
    display: inline-flex;
    gap: 40px;
    font-weight: 600;
}
.headerPublic .nav .link{
    padding-bottom: 0;
}
.headerClient {
    position: fixed;
    top: 0;
    background: #fff;
    display: inline-flex;
    border-bottom: lightgray 1px solid;
    width: 100%;
    height: 105px;
    margin: 0 auto;
    z-index: 1000;
}

.headerClient .nav {
    display: inline-flex;
    gap: 40px;
    color: #f40009;
}

.headerClient .nav a {
    color: #363140;
}

.headerClient .tel {
    border: 2px solid white;
    background: #D1C6F0;
    color: #363140;
    padding: 6px 8px;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: bold;
    transition: all 0.3s;
}

.h2 {
    text-transform: uppercase;
    font-size: 1.6rem;
    text-align: center;
    margin: 16px 0 24px 0;
    color: #363140;
}

.checkContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 25px;
}

.checkContainer span {
    cursor: pointer;
    color: #7761A9;
    font-size: 14px;
}

.check {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    font-size: 14px;
    color: #363140;
}

.checkRules {
    display: flex;
    gap: 20px;
    align-items: center;
    font-size: 14px;
    color: hsla(0, 0%, 5%, 0.6);
    margin: 20px 0;
}

.checkRules input[type="checkbox"] {
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 4px;
    appearance: none;
    margin: 0;
    padding: 0;
    min-width: 20px;
    min-height: 20px;
}


.checkRules input[type="checkbox"]:checked {
    border-color: #7761A9;
}

.checkRules input[type="checkbox"]:checked::after {
    content: "✓";
    color: #7761A9;
    font-size: 14px;
    position: relative;
    left: 2px;
}

.checkRules span {
    flex-grow: 1;
}
.bithLabel input[type="date"]{
    height:60px;
    width:100%;
}
.check input[type="checkbox"] {
    width: 20px;
    height: 20px;
    border: 2px solid #7761A9;
    border-radius: 4px;
    appearance: none;
    margin: 0;
    padding: 0;
    font-weight: 800;
}

.check input[type="checkbox"]:checked {
    border-color: #7761A9;
}

.check input[type="checkbox"]:checked::after {
    content: "✓";
    color: #7761A9;
    font-size: 14px;
    position: relative;
    left: 2px;
    font-weight: 800;

}

.subtitle {
    font-size: 16px;
    line-height: 1.3rem;
    width: 65%;
    color: #222222;
    margin: 20px auto;
}
.subtitlePass{
    margin: 10px 0;
    max-width: 100%;
}

.darkButton {
    border-radius: 15px;
    box-sizing: border-box;
    background: #7761A9;
    color: #fff;
    display: block;
    margin-bottom: 20px;
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 0;
    padding: 20px 40px;
    width: 100%;
    box-shadow: none;
}

.lightButton {
    border-radius: 15px;
    box-sizing: border-box;
    background: #E7E7E7;
    color: #111;
    display: block;
    margin-bottom: 20px;
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 0;
    padding: 20px 40px;
    width: 100%;
    box-shadow: none;
}

.header {
    background: #fff;
    border-bottom: 1px solid #ddd;
    padding: 10px;
    display: inline-flex;
    align-items: center;
    width: 100%;
}

.container {
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1400px;
    width: 100%;
    background: white;
    padding: 22px 10px;
}

.headerLogo {
    width: 100px;
}

.headerLogoText {
    font-size: 2rem;
    font-weight: bold;
}

.headerList {
    display: none;
}

.user {
    border-radius: 50%;
    padding: 10px 12px;
    background: #D1C6F0;
    transition: all .3s;
    z-index: 1000;
}
.burgerToggle {
    display: none;
}

.headerPublic .error {
    border: 1px solid red;
    background-color: #ffe6e6;
}

.headerPublic .errorMsg {
    color: red;
    font-size: 0.9em;
    margin-bottom: 10px;
}

.textBlock {
    display: flex;
    flex-direction: column;
    gap: 0;
    max-height: 400px;
    overflow-y: auto;
    padding: 10px;
    text-align: left;
}

.textBlock h2 {
    padding: 0;
    margin-top: 20px;
    font-size: 20px;
}

.textBlock h3 {
    font-size: 18px;
    padding: 0;
    margin: 0;
    color: hsla(0, 0%, 5%, .8);
}

.textBlock p {
    font-size: 14px;
    margin-top: 20px;
    color: hsla(0, 0%, 5%, .8);
    text-align: left;
}

.textBlock ul {
    list-style: circle;
    padding: 0 0 20px;
}

.textBlock ul li {
    color: hsla(0, 0%, 5%, .8);
    text-align: left;
    margin: 0 0 0 20px;
    padding: 20px 0 0;
    letter-spacing: 0;
    line-height: 140%;
}

.error {
    width: 40%;
    margin: 20px auto;
}

.bithLabel label {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
}
.bithLabel  {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.passwordContainer {
    position: relative;
    width: 100%;
}

.passwordContainer input {
    width: 100%;
}

.passwordContainer button {
    position: absolute;
    right: 20px;
    top: 6px;
    bottom: 0;
    background: none;
    font-size: 15px;
}

@media (max-width: 1200px) {
    .headerClient .nav {
        display: none
    }

    .burgerLabel {
        position: relative;
        display: block;
        height: 25px;
        width: 30px;
    }

    .burgerLabel::after, .burgerLabel::before {
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: black;
        transition: .3s;
        z-index: 1000;
    }

    .burgerLabel::before {
        content: "";
        top: 6px;
    }

    .burgerLabel::after {
        content: "";
        bottom: 6px;
    }

    .burgerToggle:checked ~ .burgerLabel::after {
        transform: rotate(45deg);
        bottom: 10px;
    }

    .burgerToggle:checked ~ .burgerLabel::before {
        transform: rotate(-45deg);
        top: 12px;
    }

    .burgerToggle:checked ~ .burgerLabel span {
        transform: rotate(-45deg);
        top: 12px;
    }

    .burgerToggle:checked ~ ul {
        left: 0;
        right: 0;
    }

    .headerList {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: no-repeat center center / cover;
        transition: ease-in-out 0.3s;
        padding: 180px 20px 0;
        z-index: -1;

        left: -100vw;
        right: 100vw;
    }

    .headerList .top {
        display: flex;
        justify-content: center;
        background: #e7e7e7;
        color: #0c0c0c;
        font-size: 16px;
        min-width: 220px;
        width: 400px;
        padding: 22px 32px;
        border-radius: 15px;
        transition: ease-in-out 0.3s;
        font-weight: 700;
    }

    .headerList .top:hover {
        background: #c7c7c7;
    }
}

@media (max-width: 1024px) {
    .headerLogo {
        width: 50px;
    }

    .headerLogoText {
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .headerClient .tel {
        display: none;
    }
    .subtitle {
        width: 100%;
        font-size: 0.7rem;
    }

    .checkRules {
        gap: 10px;
        font-size: 10px;
    }
    
}

@media (max-width: 450px) {
    .headerPublic {
        height: 60px;
    }

    .headerClient {
        height: 60px;
    }

    .headerList .top {
        width: 100%;
    }
    .subtitle,.subtitlePass{
        font-size: 14px !important;
      
    }
    label{
        font-size: 14px;
    }
}