.prizes {
    padding: 80px 0 55px;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
}

.prizes .title {
    font-size: 28px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
}

.prizeBlock {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 36px;
}
.prizeCarBl{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap:10px;
}

.prizeCard {
   
    padding: 25px 30px;
    border-radius: 20px;
    border: 1px solid hsla(0,0%,5%,.35);
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: left;
    justify-content: space-between;
}

.prizeCard h3 {
    font-size: 20px;
    font-weight: 700;
    text-align: left;
}


.prizeCard p {
    /* margin-top: -15px; */
    font-size: 15px;
    color: black;
    text-align: left;
}



.prizeCard img {
    margin-top: 55px;
    margin-bottom: 44px;
    max-height: 300px;
    width: 50%;
    object-fit: contain;
    align-self: center;
}

.prizeCard .prizeCar {
    transform: scale(2);
}
.prizeSert{
    transform: scale(1.5);
}
.prizeCard span {
    background: #EF6B00;
    border-radius: 40px;
    color: #fff;
    font-weight: 400;
    font-size: 15px;
    padding: 10px 20px;
    text-align: center;
    max-width: 152px;
    width: 100%;
}

@media (max-width: 768px) {
    .prizes {
        padding: 40px 20px 40px;
    }

    .prizeBlock {
        margin-top: 40px;
    }

   .prizes .title {
       font-size: 32px;
   }

    .prizeCard .subtitle {
        font-size: .8rem;
        color: gray;
    }

    .prizeCard h3 {
        font-size: 1.2rem;
    }

    .prizeCard p {
        font-size: .8rem;
    }
}

@media (max-width: 674px) {
.prizeCard {
   

    gap: 20px;

}




/* .prizeCard p {
    margin-top: -15px;
  
} */
/* .prizeCard:nth-child(2) p {
    margin-top: -10px;
  
} */
}
@media (max-width: 450px) {
    .prizeCard span {
        width: 65%;
        align-self: center;
    }

    .prizeBlock {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    .prizeCard h3 {
        font-size: 1rem;
    }
    .prizeCard img {
        
        margin-bottom: 20px;
        margin-top: 20px;
        
    }
    .prizeCard h3 {
        text-align: center;
    }
    
    
    .prizeCard p {
        text-align: center;
    }
}