.introduction {
    width: 100%;
    padding: 205px 40px 75px 40px;
    background: no-repeat center 20px / cover;
}
.x160{
    transform: translateY(10px);
}
.tire{
    font-weight: 400!important;
}
.sert20{
    transform: translateY(-6px);
}
.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 1400px;
    width: 100%;
}

.container .image {
    width: 90%;
}

.title {
    font-family: "signpainter";
    text-align: center;
    color: white;
    padding: 0;
    font-size: 128px;
    font-weight: 400;
    line-height: 60%;
}

.container h2 {
    margin-top: 5px;
    text-align: center;
    color: white;
    padding: 0;
    font-size: 36px;
    font-weight: 800;
}

.introductionText {
    font-size: 36px;
}

.introductionBlock {
    margin-top: 190px;
    display: flex;
    flex-direction: row;
    gap: 0;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
}

.introductionCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    /* gap: 15px; */
    /* margin-top: 200px; */
    width: 100%;
}

.introductionCard .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 40px;
    line-height: 110%;
}
.introductionCard .text p{
    font-size: 22px;
    width: 400px;
}
.introductionCard .text span{
    font-size: 15px;;
}
.introductionCard h2,
p,
span {
    color: white;
    text-align: center;
}

.introductionCard p {
    font-size: 30px;
    font-weight: lighter;
    text-align: center;
    line-height: 110%;
    font-weight: 600;
}
.introductionCard h2 {
    font-size: 36px;
    font-weight: lighter;
    text-align: center;
    line-height: 110%;
    font-weight: 800;
}

.introductionCard span {
    font-size: 14px;
    font-weight: lighter;
    text-align: center;
}

.introductionCard img {
    width: 100%;
}

.introductionCard .prize {
    width: 100%;
    transform: scale(1.3);
    position: absolute;
    bottom: 100px;
    right: -120px;
}
.introductionCard:nth-child(2){
    transform:translateX(70px) ;
}
.introductionCard .sertificate {
    width: 50%;
}
@media (max-width: 1360px) {
    .introductionBlock {
        margin-top: 60px
    }

}
@media (max-width: 810px) {
    .introductionBlock {
        margin-top: 0
    }
    .introductionCard .prize {
        width: 100%;
        transform: scale(1.3);
        position: absolute;
        bottom: 100px;
        right: -40px;
    }
    .introductionCard:nth-child(2){
        transform:translateX(0px) ;
    }
    .introductionCard .sertificate {
        width: 55%;
    }
    .container h2 {
        font-size: 20px !important;
    }

    .introductionText {
        text-align: center;
        font-size: 20px;
    }

    .title {
        font-family: "signpainter";
        text-align: center;
        color: white;
        padding: 0;
        font-size: 60px;
        font-weight: 400;
        line-height: 60%;
    }

    .introduction {
        padding: 160px 0 120px;
        background: no-repeat center -80px / cover;
    }

    .introductionBlock {
        flex-direction: column;
        gap: 40px;
    }

    .introductionCard {
        margin-top: 0;
    }

    .introductionCard .text {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        margin-top: 0;
        width: 80%;
    }

    .introductionCard .prize {
        position: static;
        transition: none;
        width: 70%;
    }
    .introductionBlock {
        margin-top: 50px;
       
    }
}

@media (max-width: 450px) {
    .introductionBlock {
        margin-top: 60px
    }

    .introduction {
        padding: 120px 0 120px;
    }

    .title {
        max-width: 270px;
    }

    .container h2 {
        font-size: 15px !important;
    }

    .introductionText {
        text-align: center;
        font-size: 14px;
    }
    .introductionCard .text h2{
        font-size: 24px;
    }
    .introductionCard .text p{
        font-size: 18px;
        width: 320px;
    }
    .introductionCard .text span{
        font-size: 12px;
    }
    .introductionCard p {
        font-size: 16px;
   
    }
    .introductionCard h2 {
        font-size: 22px!important;
     
    }
}

@media (max-width: 411px) {
    .introductionBlock {
        margin-top: 100px
    }
}