.users {

}

.table {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.row {
    display: grid;
    grid-template-columns: 5% 1fr 1fr 1fr 1fr;
    gap: 16px;
    border-radius: 4px;
    text-align: left;
    border-bottom: 1px solid #eee;
    padding: 6px 0 12px 0;
    box-sizing: border-box;
}

.link {
    color: #369;
}

.id,
.name,
.email,
.birthDate,
.isVerified {
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

.name {
    color: #369;
}

.id:before,
.name:before,
.email:before,
.birthDate:before,
.isVerified:before {
    font-size: .6rem;
    font-weight: normal;
}

.id:before {
    content: "ID";
}

.name:before {
    content: "Название";
}

.email:before {
    content: "Email";
}

.birthDate:before {
    content: "Дата рождения";
}

.isVerified:before {
    content: "Верификация";
}

.unverified {
    color: red;
}

.verified {
    color: green;
}

.activePage {
    color: green;
}

.inactivePage {
    color: red;
}