.prize {
    padding: 20px;
}

.h1 {
    margin-bottom: 20px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.label {
    font-size: 16px;
    color: #333;
}

.input {
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    width: 300px;
}

.controls {
    display: flex;
    gap: 20px;
}

.button {
    padding: 10px 20px;
    background-color: #f1f1f1;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
}

.buttonPrimary {
    background-color: #4CAF50;
    color: white;
}

.button:hover {
    opacity: 0.8;
}

.buttonPrimary:hover {
    background-color: #45a049;
}
