.export {
    max-width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
}

.h1 {
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    text-align: left;
    font-size: 2rem;
    font-weight: bold;
    border-bottom: 1px solid #eee;
}

.controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding-bottom: 16px;
    gap: 20px;
}

.exportButton {
    background: none;
    border: none;
    font-size: 1rem;
    text-align: start;
    color: #2b7817;
    background: #f2f2f2;
    padding: 8px 16px;
    border-radius: 4px;
}

.exportButton:hover {
    background: #ccc;
}

.back {
    color: #369;
    flex: 1;
}

.table {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.row {
    display: grid;
    align-items: center;
    grid-template-columns: 10% 4fr 2fr 1fr 2fr 2fr 2fr;
    gap: 16px;
    border-radius: 4px;
    text-align: left;
    border-bottom: 1px solid #eee;
    padding: 6px 0 12px 0;
    box-sizing: border-box;
}

.id, .user, .receipt, .number, .code, .phone, .email, .isAccepted, .receiptCreated, .acceptedAt {
    display: flex;
    flex-direction: column;
    font-weight: normal;
}

.id:before,
.user:before,
.code:before,
.phone:before,
.email:before,
.receiptCreated:before,
.acceptedAt:before {
    font-size: .6rem;
    font-weight: normal;
}

.id:before {
    content: "ID";
}

.user:before {
    content: "Участник";
}

.code:before {
    content: "Код";
}

.phone:before {
    content: "Телефон";
}

.email:before {
    content: "Email";
}

.receiptCreated:before {
    content: "Создано";
}

.acceptedAt:before {
    content: "Принято";
}