.newReceipt {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 23%;
}

.h1 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 16px;
}

.fileItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.fileName {
    color: green;
}

.removeButton {
    background: none;
    font-size: 2rem;
    color: red;
}

.previewImage {
    border-radius: 4px;
    width: 100%;
    max-width: 300px;
    margin-top: 16px;
}

.subtitle {
    line-height: 24px;
    color: #888;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    border: 1px solid black;
    padding: 48px;
    margin: 32px 0;
    border-radius: 18px;
}

.form label {
    line-height: 1.4rem;
    width: 70%;
}

.form .buttons {
    display: inline-flex;
    gap: 10px;
}

.formats {
    color: #aaa;
    margin-bottom: 16px;
}

.select, .send, .clear  {
    background: #eee;
    color: #111;
    width: 50%;
    padding: 12px 40px;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: bold;
}

.photoReceipt {
    text-align: center;
}

.select {
    background: #111;
    color: #fff;
    text-align: center;
}

@media (max-width: 1200px) {
    .form .buttons {
        flex-direction: column;
        width: 100%;
    }

    .select, .send, .clear  {
        width: 100%;
    }

    .form label {
        width: 100%;
    }

    .newReceipt {
        padding: 60px 10%;
    }
}

@media (max-width: 768px) {
    .newReceipt {
        padding: 60px 20px;
    }

    .form {
        padding: 20px;
    }
}