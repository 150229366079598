.client {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.h1 {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: start;
    margin-top: 100px;
}

.public {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.container {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding: 100px 20px;
    background: left 84px bottom -111px / 180px auto no-repeat;
}

.public .title {
    font-size: 42px;
    text-align: left;
    font-weight: 700;
}

.public .subtitle {
    color: gray;
    line-height: 140%;
    text-align: left;
    margin-top: 22px;
}

.public .block {
    display: inline-flex;
    margin-top: 100px;
    padding: 14px;
    background: #e7e7e7;
    border-radius: 20px;
    width: 100%;
}

.public .block .menu {
    display: flex;
    flex-direction: column;
    width: 20%;
    padding: 15px 30px 15px 15px;
    gap: 15px;
}

.public .block .menu a,
.public .block .menu button {
    background: #7761A9;
    font-weight: bold;
    color: white;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 12px;
    text-align: start;
    font-size: inherit;
}

.public .block .menu a:hover,
.public .block .menu button:hover {
    background: #9b80db;
}

.activeLink {
    background: #D1C6F0 !important;
    color: black !important;
}

/*.public .block .menu a:hover,*/
/*.public .block .menu button:hover {*/
/*    color: white;*/
/*    background: #111;*/
/*}*/

.public .block .menu button {
    margin-top: auto;
}

.public .block .content {
    width: 80%;
    background: #fff;
    border-radius: 12px;
}

.public .block .draw {
    display: flex;
    flex-direction: column;
    padding: 22px 44px 22px 22px;
    gap: 20px;
}

.public .block .draw p {
    color: black;
    background: white;
    min-width: 220px;
    width: 100%;
    text-align: left;
    padding: 22px 32px;
    border-radius: 15px;
    line-height: 140%;
    transition: 0.3s;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
}

.public .block .draw p:hover {
    color: white;
    background: black;
}

.public .block .winners {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: 75%;
    background: white;
    padding: 32px 26px 26px;
    border-radius: 10px;
}

.public .block .winners input {
    width: 100%;
    max-width: 520px;
    border: 1px solid hsla(0, 0%, 5%, .35);
    border-radius: 70px;
    line-height: 140%;
    padding: 12px 14px 12px 42px;
    font-size: 14px;
}

.winners ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-top: 40px;
}

.winners ul li {
    font-size: 14px;
    cursor: pointer;
}

@media (max-width: 1200px) {
    .public .block {
        flex-direction: column;
    }

    .public .block .content {
        width: 100%;
    }

    .public .block .menu {
        width: 100%;
        padding: 0 0 20px;
    }
}

@media (max-width: 768px) {
    .public .title {
        font-size: 32px;
    }

    .public .block .menu a:hover,
    .public .block .menu button:hover {
        background: #7761A9;
    }
}

@media (max-width: 450px) {
    .h1 {
        margin-top: 20px;
    }

    .public .title {
        font-size: 22px;
    }
}