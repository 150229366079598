.raffles {
    max-width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
}

.h1 {
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    text-align: left;
    font-size: 2rem;
    font-weight: bold;
    border-bottom: 1px solid #eee;
}

.detailsLink {
    color: #369;
    font-weight: bold;
    margin-bottom: 20px;
    text-decoration: none;
}

.table {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.row {
    display: grid;
    grid-template-columns: 1fr 1fr auto auto;
    gap: 16px;
    border-radius: 4px;
    text-align: left;
    border-bottom: 1px solid #eee;
    padding: 6px 0 12px 0;
    box-sizing: border-box;
}

.name {
    color: #369;
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

.link {
    color: #369;
}

.name:before {
    content: "Название";
    font-size: .6rem;
    font-weight: normal;
}

.id {
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

.id:before {
    content: "ID";
    font-size: .6rem;
    font-weight: normal;
}

.started, .finished {
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

.started:before {
    content: "Дата старта";
    font-size: .6rem;
    font-weight: normal;
}

.finished:before {
    content: "Дата финиша";
    font-size: .6rem;
    font-weight: normal;
}

th, td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

.detailsLink:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    h1 {
        font-size: 28px;
    }

    .detailsLink {
        font-size: 18px;
    }

    table, thead, tbody, th, td, tr {
        display: block;
    }

    th {
        display: none;
    }

    tr {
        margin-bottom: 8px;
    }

    tr:last-child {
        margin-bottom: 0;
    }

    td {
        text-align: right;
        position: relative;
        padding-left: 50%;
        border-bottom: none;
        background: #fff;
    }

    td:last-child {
        border-bottom: 1px solid #ccc;
    }

    td::before {
        content: attr(data-label);
        position: absolute;
        left: 8px;
        width: 50%;
        text-align: left;
        font-weight: bold;
    }
}