.admin {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #f4f4f5;
}

.projects {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0;
    padding-right: 10px;
    box-sizing: border-box;
    width: 100%;
    border-top: 1px solid #e2e2e2;
    padding-top: 10px;
}

.admin .content {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    overflow-y: scroll;
}

/* .admin .content::-webkit-scrollbar {
    display: none;
} */
.container::-webkit-scrollbar {
    width: 8px; /* Ширина полосы прокрутки */
  }
  
  .container::-webkit-scrollbar-thumb {
    background: #888; /* Цвет "ползунка" */
    border-radius: 4px; /* Скругленные углы ползунка */
  }
  
  .container::-webkit-scrollbar-thumb:hover {
    background: #555; /* Цвет ползунка при наведении */
  }
  
  .container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Цвет фона полосы прокрутки */
  }
  
.button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    box-sizing: border-box;
    border-radius: 5px;
    color: #000;
    font-weight: bold;
}

.button:hover {
    background: #e2e2e2;
}

.overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
    cursor: pointer;
}

.modalContent {
    background: #fff;
    position: absolute;
    width: 100%;
    max-width: 1000px;
    left: 0;
    right: 0;
    margin: auto auto;
    border: 1px solid #888;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
}

.modalOpened {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 10;
    margin: auto auto;
    display: block;
}

.modalClosed {
    display: none;
}

h2 {
    width: 100%;
    text-align: left;
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
    font-weight: bold;
}
