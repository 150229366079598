.footer {
    width: 100%;
    background: #fff;
    height: 50px;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
}
