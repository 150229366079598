.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background: white;
    border-radius: 4px;
    padding: 20px;
    max-width: 500px;
    width: 100%;
    text-align: start;
    display: flex;
    flex-direction: column;
    height: 80%;
    gap: 20px;
    overflow: auto;
}

.modal textarea {
    border-radius: 4px;
    padding: 12px 16px;
    font-size: 16px;
    width: 100%;
    min-height: 100px;
    max-height: 600px;
    box-sizing: border-box;
    background: #f9f9f9;
    border: 1px solid #d1d1d1;
    box-shadow: inset 0 1px 3px #0000001a;
    color: #0c0c0c;
    transition: all .3s ease;
    overflow-y: auto;
}

.modal textarea:focus {
    background: #fff;
    border-color: #1b1b22;
    box-shadow: 0 0 8px #007aff66;
    outline: none;
}

.modal .controls {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.modal button {
    flex: 1;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
}

.transition {
    background: rgba(103, 186, 103, 0.04)!important;
    color: rgba(16, 113, 205, 0.7)!important;
    border: 1px solid rgba(20, 95, 179, 0.4)!important;
}

.transition:active {
    background: rgba(16, 113, 205, 0.7)!important;
    color: white!important;
    border: 1px solid rgba(20, 95, 179, 0.4)!important;
}

.modal button:nth-of-type(1) {
    background: rgba(103, 186, 103, 0.04);
    color: rgba(243, 3, 59, 0.7);
    border: 1px solid rgba(186, 11, 37, 0.4);
}

.modal button:nth-of-type(1):active {
    background: rgba(243, 3, 59, 0.7);
    color: white;
    border: 1px solid rgba(186, 11, 37, 0.4);
 }

.modal button:nth-of-type(2) {
    background: rgba(103, 186, 103, 0.04);
    color: rgba(0, 128, 0, 0.7);
    border: 1px solid rgba(103, 186, 103, 0.4);
}

.modal button:nth-of-type(2):active {
    background: rgba(0, 128, 0, 0.7);
    color: white;
    border: 1px solid rgba(103, 186, 103, 0.4);
}

.preview {
    width: 100%;
    max-height: 500px;
    object-fit: contain;
}

.modal select {
    border-radius: 4px;
    padding: 10px;
    font-size: 16px;
    appearance: none;
}